<template>
  <div class="shieldo-container">
    <div class="earning-text to-center-abs">
      {{ text }}
    </div>
    <div class="earning-shield to-center-abs">
      <div class="earning-container mx-auto">
        <div
          class="earning-icon to-center-abs"
          :style="{
            backgroundImage:
              'url(' + $whitelabel.currencies.primary.accent + ')',
          }"
        >
          <div
            class="earning-btn position-absolute gradient-indent glow text-center"
          >
            <span class="earning-amount golden-gradient-text">
              {{ amount }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    amount: {
      type: [String, Number],
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.shieldo-container {
  top: 20%;
  right: -400px;
  position: absolute;
  width: 300px;

  .earning-text {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-shadow: 1px 1px 2px #1ae5f4, 0 0 25px #1ae5f4, 0 0 5px #1ae5f4;
    text-align: center;
    top: 10px;
    z-index: 25;
    line-height: 1.4;
  }
  .earning-shield {
    width: 225px;
    height: 261px;
    top: 50px;
  }
  .earning-container {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 128px;
    margin: 0 8px 0 8px;
    background: url(~@/assets/img/common/earning-shield-bg.png) center/100% 100%
      no-repeat;

    .earning-icon {
      top: 15%;
      width: 160px;
      height: 160px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .earning-btn {
      top: 120px;
      height: 47px;
      width: 150%;
      left: 50%;
      transform: translateX(-50%);
    }

    .earning-amount {
      font-size: 36px;
    }
  }
}
</style>
